declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'google-cast-launcher': any;
    }
  }
}
export const CastButton = ({ ...props }) => {
  return <google-cast-launcher style={{ cursor: 'pointer' }} {...props} />;
};
