import { API, graphqlOperation } from 'aws-amplify';

import { GET_SCHEDULE } from '../graphql/queries';

type ScheduleQueryResult = {
  tasks: {
    person: string[];
    task: string;
    cron: string;
    external_id: string;
    id: string;
  }[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isGraphQLResult(arg: any): arg is { data: ScheduleQueryResult } {
  return arg && arg.data;
}

export const fetchSchedule = async (): Promise<
  ScheduleQueryResult['tasks']
> => {
  const response = await API.graphql<ScheduleQueryResult>(
    graphqlOperation(GET_SCHEDULE)
  );

  if (isGraphQLResult(response)) {
    return response.data.tasks;
  }
  throw new Error('Error fetching schedule');
};
