import gql from 'graphql-tag';

export const GET_SCHEDULE = gql`
  query Schedule {
    tasks {
      person
      task
      cron
      external_id
      id
    }
  }
`;
