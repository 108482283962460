/// <reference types="@types/chromecast-caf-receiver" />
import React, {
  ReactNode,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import { createContext } from 'react';
import { CastLoader } from './CastLoader';
// import { cast } from 'chromecast-caf-receiver';
import { TEXT_NAMESPACE, AUTH_NAMESPACE } from './config';
import { Hub } from 'aws-amplify';
type CastReceiverContextType = {
  initialized: boolean;
};
const CastReceiverContext = createContext<CastReceiverContextType>({
  initialized: false,
});
interface CastReceiverProviderProps {
  children?: ReactNode;
}

export const CastReceiverProvider = ({
  children,
}: CastReceiverProviderProps) => {
  const [castInitialized, setCastInititalized] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [castReceiverContext, setCastReceiverContext] = useState<unknown>(null);

  useEffect(() => {
    CastLoader.loadRecieverSDK().then(() => {
      setTimeout(() => {
        setCastInititalized(true);
      }, 1000);
    });
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAuthMessage = useCallback((event: any) => {
    console.log('Received auth message:', event.data);
    Hub.dispatch('alpheus/auth', {
      event: 'alpheus:auth',
      data: event.data,
    });
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTextMessage = useCallback((event: any) => {
    console.log('Received text message:', event.data);
    Hub.dispatch('alpheus/text', {
      event: 'alpheus:text',
      data: event.data,
    });
  }, []);

  useEffect(() => {
    if (castInitialized) {
      /**
       * Cast receiver context as variable
       */

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      // const castDebugLogger = cast.debug.CastDebugLogger.getInstance();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const instance = cast.framework.CastReceiverContext.getInstance();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      // instance.addEventListener(cast.framework.system.EventType.READY, () => {
      //   if (!castDebugLogger.debugOverlayElement_) {
      //     // Enable debug logger and show a 'DEBUG MODE' overlay at top left corner.
      //     castDebugLogger.setEnabled(true);
      //   }
      // });

      setCastReceiverContext(instance);
      instance.addCustomMessageListener(AUTH_NAMESPACE, handleAuthMessage);
      instance.addCustomMessageListener(TEXT_NAMESPACE, handleTextMessage);

      instance.start({
        disableIdleTimeout: true,
        maxInactivity: 300,
        statusText: 'Ready to present',
      });

      return () => {
        instance.removeCustomMessageListener(AUTH_NAMESPACE, handleAuthMessage);
        instance.removeCustomMessageListener(TEXT_NAMESPACE, handleTextMessage);
        console.log('un loading');
      };
    }
  }, [castInitialized, handleAuthMessage, handleTextMessage]);

  const value = useMemo(() => {
    const value = {
      initialized: castInitialized,
    };
    return value;
  }, [castInitialized]);
  return (
    <CastReceiverContext.Provider value={value}>
      {children}
    </CastReceiverContext.Provider>
  );
};

export const useReceiverCast = () => {
  const context = useContext(CastReceiverContext);
  return { ...context };
};
