import { config } from './config';
import { Amplify, Auth } from 'aws-amplify';
import { PollyClient, SynthesizeSpeechCommand } from '@aws-sdk/client-polly';

import Semaphore from 'semaphore-async-await';

let AudioContext: unknown = undefined;
let play: (audioBuffer: unknown) => Promise<void> = async () =>
  Promise.resolve();
export let unlock = (callback: () => void) => {
  console.info('unmute');
};

// Check if the browser supports web audio. Safari wants a prefix.
if ('AudioContext' in window || 'webkitAudioContext' in window) {
  //@ts-expect-error fix type error
  AudioContext = window.AudioContext || window.webkitAudioContext;

  //@ts-expect-error fix type error
  const context = new AudioContext();
  const gainNode = context.createGain();
  gainNode.gain.value = 1; // set volume to 100%

  play = async (audioBuffer: unknown) => {
    const buffer = await context.decodeAudioData(audioBuffer);
    const source = context.createBufferSource();
    source.buffer = buffer;
    source.connect(context.destination);
    source.start();
    await new Promise<void>((resolve) => {
      source.addEventListener('ended', () => {
        console.log('ended');
        resolve();
      });
    });
  };

  unlock = (callback: () => void) => {
    // console.log('unlocking');
    // create empty buffer and play it
    const buffer = context.createBuffer(1, 1, 22050);
    const source = context.createBufferSource();
    source.buffer = buffer;
    source.connect(context.destination);

    // play the file. noteOn is the older version of start()
    source.start ? source.start(0) : source.noteOn(0);

    setTimeout(function () {
      if (
        source.playbackState === source.PLAYING_STATE ||
        source.playbackState === source.FINISHED_STATE
      ) {
        callback();
      }
    }, 0);
  };
}

// A Semaphore with one permit is a lock
const speechlock = new Semaphore(1);

export async function synthesizeSpeech(text: string, voiceId = 'Joanna') {
  console.log(text);

  const amplifyConfig = Amplify.configure() as { Auth: { region: string } };
  const polly = new PollyClient({
    //@ts-expect-error fix type error
    region: config.config?.REGION
      ? //@ts-expect-error fix type errorv
        config.config.REGION
      : amplifyConfig.Auth.region,
    credentials: await Auth.currentCredentials(),
  });

  const synthesizeSpeechCommand = new SynthesizeSpeechCommand({
    Engine: 'neural',
    OutputFormat: 'mp3',
    Text: `<speak><prosody rate="85%">${text}</prosody></speak>`,
    VoiceId: voiceId,
    TextType: 'ssml',
    SampleRate: '24000',
  });
  try {
    await speechlock.acquire();
    const data = await polly.send(synthesizeSpeechCommand);
    const response = new Response(data.AudioStream as ReadableStream);
    const audio = await response.arrayBuffer();
    if (audio === undefined) {
      console.log('audio is undefined');

      return;
    }

    const uInt8Array = new Uint8Array(audio);
    const arrayBuffer = uInt8Array.buffer;

    await play(arrayBuffer);
  } catch (error) {
    console.error('Amazon Polly failed:', error);
  } finally {
    await speechlock.release();
  }
}
