// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  CountDocumentsOptions,
  // CreateIndexesOptions,
  DeleteOptions,
  DeleteResult,
  Document,
  EstimatedDocumentCountOptions,
  Filter,
  FindCursor,
  FindOneAndUpdateOptions,
  FindOptions,
  // IndexSpecification,
  InsertOneOptions,
  InsertOneResult,
  ModifyResult,
  // ObjectId,
  OptionalUnlessRequiredId,
  SortDirection,
  UpdateFilter,
  UpdateOptions,
  UpdateResult,
  WithId,
} from 'mongodb';
import {
  // filter as _filter,
  // find as _find,
  // remove as _remove,
  // update as _update,
  // size as _size,
  cloneDeep,
} from 'lodash';
import debug from 'debug';
import { ulid } from 'ulid';

import type { Agenda } from './agenda';
import type { IJobParameters } from './types/JobParameters';
import { ICollection, JobDbRepositoryBase } from './JobDbRepository';

const log = debug('agenda:InMemoryJobDbRepository');

function isNullUndefined(var1: unknown) {
  return var1 == null || var1 === undefined;
}

function isEqualWithNullUndefined(var1: unknown, var2: unknown) {
  return (
    var1 === var2 ||
    (var1 == null && var2 == null) ||
    (var1 == null && var2 === undefined) ||
    (var1 === undefined && var2 == null)
  );
}

function matchesFilter<T>(_doc: T, filter: Filter<T>): boolean {
  const doc = _doc as Record<string, unknown>;
  for (const key in filter) {
    if (key === '$or') {
      const conditions = filter[key];
      if (!Array.isArray(conditions)) {
        throw new Error('$or operator requires an array');
      }
      // Track whether any condition in $or array matches
      let hasMatchingCondition = false;
      for (const condition of conditions) {
        if (matchesFilter(doc, condition as Filter<T>)) {
          hasMatchingCondition = true;
          break; // Exit the loop early if a match is found
        }
      }
      if (!hasMatchingCondition) {
        return false;
      }
    } else if (typeof filter[key] === 'object' && filter[key] !== null) {
      for (const operator in filter[key]) {
        const value = filter[key][operator];
        switch (operator) {
          case '$eq':
            log.extend('matchesFilter')(
              `${operator} ${key} ${
                doc[key]
              } ${value} ${isEqualWithNullUndefined(doc[key], value)}`
            );

            if (!isEqualWithNullUndefined(doc[key], value)) return false;
            break;
          case '$gt':
            if (isNullUndefined(doc[key])) return false;
            if (isNullUndefined(value)) return false;

            log.extend('matchesFilter')(
              `${operator} ${key} ${doc[key]} ${value} ${!(doc[key] <= value)}`
            );
            if (doc[key] <= value) return false;
            break;
          case '$gte':
            if (isNullUndefined(doc[key])) return false;
            if (isNullUndefined(value)) return false;
            log.extend('matchesFilter')(
              `${operator} ${key} ${doc[key]} ${value} ${!(doc[key] < value)}`
            );
            if (doc[key] < value) return false;
            break;
          case '$lt':
            if (isNullUndefined(doc[key])) return false;
            if (isNullUndefined(value)) return false;
            log.extend('matchesFilter')(
              `${operator} ${key} ${doc[key]} ${value} ${!(doc[key] >= value)}`
            );
            if (doc[key] >= value) return false;
            break;
          case '$lte':
            if (isNullUndefined(doc[key])) return false;
            if (isNullUndefined(value)) return false;
            log.extend('matchesFilter')(
              `${operator} ${key} ${doc[key]} ${value} ${!(doc[key] > value)}`
            );
            if (doc[key] > value) return false;
            break;
          case '$ne':
            log.extend('matchesFilter')(
              `${operator} ${key} ${
                doc[key]
              } ${value} ${!isEqualWithNullUndefined(doc[key], value)}`
            );
            if (isEqualWithNullUndefined(doc[key], value)) return false;
            break;
          default:
            throw new Error(`Unsupported operator: ${operator}`);
        }
      }
    } else {
      log.extend('matchesFilter')(
        `base ${key} ${doc[key]} ${filter[key]} ${!isEqualWithNullUndefined(
          doc[key],
          filter[key]
        )}`
      );
      if (!isEqualWithNullUndefined(doc[key], filter[key])) return false;
    }
  }
  return true;
}

class InMemoryCollection<T> implements ICollection<T> {
  readonly store: WithId<T>[] = [];

  async updateOne(
    filter: Filter<T>,
    update: UpdateFilter<T> | Partial<T>,
    options?: UpdateOptions
  ): Promise<UpdateResult<T>> {
    log.extend('updateOne')('filter=%O options=%O', filter, options);

    const doc = await this._find(filter);
    log.extend('store')('%O', this.store);

    if (doc) {
      updateDocument(update, doc as WithId<T>);
      log.extend('store')('%O', this.store);

      return {
        acknowledged: true,
        matchedCount: 1,
        modifiedCount: 1,
        upsertedCount: 0,
        upsertedId: null,
      };
    } else {
      return {
        acknowledged: true,
        matchedCount: 0,
        modifiedCount: 0,
        upsertedCount: 0,
        upsertedId: null,
      };
    }
  }

  findOne(): Promise<WithId<T>>;
  findOne(filter: Filter<T>): Promise<WithId<T>>;
  findOne(
    filter: Filter<T>,
    options: FindOptions<Document>
  ): Promise<WithId<T>>;

  findOne(): Promise<T>;
  findOne(filter: Filter<T>): Promise<T>;
  findOne(filter: Filter<T>, options?: FindOptions<Document>): Promise<T>;
  findOne(
    filter?: unknown,
    options?: unknown
  ): Promise<WithId<T>> | Promise<T> | Promise<T> | Promise<T> {
    log.extend('findOne')('filter=%O options=%O', filter, options);

    log.extend('store')('%O', this.store);

    return Promise.resolve(cloneDeep(this._find(filter) as WithId<T>));
  }

  private _find(filter: unknown): WithId<T> | T {
    return (
      this.store.find((doc) => {
        const res = matchesFilter(doc, filter as Filter<WithId<T>>);

        log.extend('_find')(
          `%O => ${JSON.stringify(filter, null, 3)} = %O`,
          doc,
          res
        );

        return res;
      }) || null
    );
  }

  find(): FindCursor<WithId<T>>;
  find(
    filter: Filter<T>,
    options?: FindOptions<Document>
  ): FindCursor<WithId<T>>;
  find<T extends Document>(
    filter: Filter<T>,
    options?: FindOptions<Document>
  ): FindCursor<T>;
  find(
    filter?: unknown,
    options?: unknown
  ): FindCursor<WithId<T>> | FindCursor<T> {
    log('InMemoryCollection find filter=%O options=%O', filter, options);

    throw new Error('Method not implemented.');
  }
  async deleteMany(
    filter?: Filter<T>,
    options?: DeleteOptions
  ): Promise<DeleteResult> {
    log('InMemoryCollection deleteMany filter=%O options=%O', filter, options);
    const lenBefore = this.store.length;
    this.store.splice(
      0,
      this.store.length,
      ...this.store.filter(
        (doc) => !matchesFilter(doc, filter as Filter<WithId<T>>)
      )
    );
    return {
      acknowledged: true,
      deletedCount: lenBefore - this.store.length,
    };
  }
  countDocuments(
    filter?: Document,
    options?: CountDocumentsOptions
  ): Promise<number> {
    log.extend('countDocuments')('filter=%O options=%O', filter, options);

    throw new Error('Method not implemented.');
  }
  async updateMany(
    filter: Filter<T>,
    update: UpdateFilter<T>,
    options?: UpdateOptions
  ): Promise<UpdateResult<T>> {
    console.log(filter);
    console.log(update);
    console.log(options);

    let matchedCount = 0;

    this.store.forEach((doc) => {
      if (matchesFilter(doc, filter as Filter<WithId<T>>)) {
        matchedCount += 1;
        updateDocument(update, doc as WithId<T>);
      }
    });

    log(
      'InMemoryCollection updateMany filter=%O options=%O update=%O',
      filter,
      options,
      update
    );

    return {
      acknowledged: true,
      matchedCount,
      modifiedCount: matchedCount,
      upsertedCount: 0,
      upsertedId: null,
    };
  }
  async findOneAndUpdate(
    filter: Filter<T>,
    update: UpdateFilter<T>,
    options?: FindOneAndUpdateOptions
  ): Promise<ModifyResult<T>> {
    const res: ModifyResult<T> = {
      ok: 0,
      value: null,
    };
    log.extend('findOneAndUpdate')(
      `filter=${JSON.stringify(filter, null, 3)} options=%O update=%O`,
      options,
      update
    );
    let doc: WithId<T> = (await this._find(filter)) as WithId<T>;
    if (doc) {
      updateDocument<T>(update, doc);
      log.extend('store')('%O', this.store);
      res.ok = 1;
      res.value = cloneDeep(doc);
    } else {
      if (options?.upsert) {
        doc = this._create({
          ...update['$set'],
          ...update['$setOnInsert'],
        });
        res.ok = 1;
        res.value = doc;
      }
    }

    log.extend('findOneAndUpdate').extend('return')('%O', res);
    return res;
  }

  private _create(_record: Readonly<Partial<T>>) {
    const doc: WithId<T> = {
      _id: ulid(),
      ..._record,
    } as WithId<T>;
    this.store.push(doc);
    return doc;
  }

  estimatedDocumentCount(
    options?: EstimatedDocumentCountOptions
  ): Promise<number> {
    log('InMemoryCollection estimatedDocumentCount options=%O', options);
    throw new Error('Method not implemented.');
  }
  async insertOne(
    doc: OptionalUnlessRequiredId<T>,
    options?: InsertOneOptions
  ): Promise<InsertOneResult<T>> {
    log('InMemoryCollection insertOne doc=%O options=%O', doc, options);

    const res = this._create(doc as Partial<T>);
    return {
      acknowledged: true,
      insertedId: res._id,
    };
  }
}

export class InMemoryJobDbRepository extends JobDbRepositoryBase {
  _collection: InMemoryCollection<IJobParameters>;

  constructor(protected override agenda: Agenda) {
    super(agenda);
    log('InMemoryJobDbRepository create');

    this._collection = new InMemoryCollection();
  }

  get collection(): ICollection<IJobParameters<unknown>> {
    return this._collection;
  }

  get sort(): { [key: string]: SortDirection } {
    return {};
  }
  async connect(): Promise<void> {
    log('InMemoryJobDbRepository.connect');

    this.agenda.emit('ready');
  }
}
function updateDocument<T>(update: UpdateFilter<T>, doc: WithId<T>) {
  log.extend('updateDocument')('before update=%O doc=%O', update, doc);
  for (const operator of Object.keys(update)) {
    if (operator === '$set') {
      for (const key in update['$set']) {
        doc[key] = update[operator][key];
      }
    } else if (operator === '$unset') {
      for (const key in update['$unset']) {
        //@ts-expect-error TODO fix type
        delete doc[key];
      }
    } else if (operator === '$inc') {
      for (const key in update['$inc']) {
        //@ts-expect-error TODO fix type
        doc[key] += update[operator][key];
      }
    } else if (operator === '$push') {
      for (const key in update['$push']) {
        doc[key].push(update[operator][key]);
      }
    } else if (operator === '$addToSet') {
      for (const key in update['$addToSet']) {
        doc[key].push(update[operator][key]);
      }
    } else if (operator === '$pop') {
      for (const key in update['$pop']) {
        //@ts-expect-error TODO fix type
        doc[key].pop();
      }
    } else {
      throw new Error(`Unsupported operator: ${operator}`);
    }
  }

  log.extend('updateDocument')('after doc=%O', doc);
}
