import React, { useEffect, useState } from 'react';
import { config, startSchedule, synthesizeSpeech } from '@alpheus/ui';
import { Hub, Auth, PubSub } from 'aws-amplify';
import AWS, { STS } from 'aws-sdk';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './app.module.css';
import { CastMediaPlayer, useReceiverCast } from '@alpheus/cast';
import { Task } from '@alpheus/model';
interface GetCallerIdentityResponse {
  UserId?: string;
  Account?: string;
  Arn?: string;
}

async function getCallerIdentity(): Promise<GetCallerIdentityResponse> {
  const sts = new STS();
  const identity = await sts.getCallerIdentity().promise();

  console.log('Caller Identity:', identity);
  return identity;
}

type AccessData = {
  token: string;
  identityId: string;
};

const DebugCast = () => {
  const [creds, setCreds] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [user, setUser] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);

  return (
    <>
      debug cast
      <br />
      <div>
        user
        <br />
        <input
          //
          value={user || ''}
          onChange={(e) => {
            setUser(e.target.value);
          }}
          style={{ width: '500px' }}
        />
        <br />
        password
        <br />
        <input
          //
          type="password"
          value={password || ''}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          style={{ width: '500px' }}
        />
        <br />
        <button
          onClick={async () => {
            Hub.dispatch('alpheus/auth', {
              event: 'alpheus:auth',
              data: {
                user,
                password,
              },
            });
          }}
        >
          login
        </button>
        <br />
        token
        <br />
        <textarea
          //
          style={{ width: '500px' }}
          cols={5}
          value={token || ''}
          onChange={(e) => {
            setToken(e.target.value);
          }}
        />
        <br />
        refresh token
        <br />
        <textarea
          //
          style={{ width: '500px' }}
          cols={5}
          value={refreshToken || ''}
          onChange={(e) => {
            setRefreshToken(e.target.value);
          }}
        />
        <br />
        credentials
        <br />
        <textarea
          //
          style={{ width: '500px' }}
          cols={5}
          value={creds || ''}
          onChange={(e) => {
            setCreds(e.target.value);
          }}
        />
        <br />
        <button
          onClick={async () => {
            Hub.dispatch('alpheus/auth', {
              event: 'alpheus:auth',
              data: {
                ...JSON.parse(creds as string),
                token: token,
                refreshToken: refreshToken,
              },
            });
          }}
        >
          login
        </button>
        <br />
        <button
          onClick={async () => {
            Auth.signOut();
          }}
        >
          Sign out
        </button>
      </div>
      <div> test</div>
    </>
  );
};
export function App() {
  const { initialized } = useReceiverCast();
  const [user, setUser] = useState<string>('');
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    startSchedule();
  }, []);
  useEffect(() => {
    console.log('seting sub');
    const sub = PubSub.subscribe('tasks/kid1').subscribe({
      next: async (data) => {
        //

        console.log('Message received', data);
        //@ts-expect-error fix types
        setMessage(data.value.name);
        setTimeout(() => {
          setMessage(null);
        }, 3000);
        //@ts-expect-error TODO fix type
        await synthesizeSpeech(data.value.name);
      },
      error: (error) => console.error(error),
      complete: () => console.log('Done'),
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);
  useEffect(() => {
    const hubAuthListenerCancelToken = Hub.listen(
      'alpheus/auth',
      async (event) => {
        console.log(event);
        const accessData: AccessData = event.payload.data;
        if (accessData) {
          try {
            if (accessData.identityId) {
              await loginFromToken(accessData);
            } else {
              await userLogin(accessData);
            }
          } catch (error) {
            console.log('error signing in', error);
            await synthesizeSpeech('Error logging in ');
            return;
          }
          const identity = await getCallerIdentity();
          console.log(identity);
          setUser(`${identity.Account} ${identity.UserId} ${identity.Arn}`);
          await synthesizeSpeech(`Starting up`);
        }
      }
    );

    const hubTextListenerCancelToken = Hub.listen(
      'alpheus/text',
      async (event) => {
        console.log(event);
        const text = event.payload.data.text as string;
        await synthesizeSpeech(text);
      }
    );
    return () => {
      hubAuthListenerCancelToken();
      hubTextListenerCancelToken();
    };
  }, [setUser]);

  useEffect(() => {
    const hubNotifyListenerCancelToken = Hub.listen(
      'alpheus/notify',
      async (event) => {
        console.log(event);
        const task: Task = event.payload.data;
        await synthesizeSpeech(
          `${task.person.join('. ')}. 
           ${task.person.join('. ')}. 
           notification for: ${task.person.join('. ')}. 
           ${task.task}. . .
           notification for: ${task.person.join('. ')}. 
           ${task.task}
           
          `
        );
      }
    );

    return () => {
      hubNotifyListenerCancelToken();
    };
  }, []);
  return (
    <div>
      <h1>Alpheus Receiver</h1>
      {message ? <p>message: {message}</p> : null}
      <p>id user: {user}</p>
      {process.env.NX_TASK_TARGET_CONFIGURATION === 'development' ? (
        <DebugCast />
      ) : null}
      <p>initialized: {initialized ? 'true' : 'false'}</p>
      <div
        style={{
          display: 'block',
          position: 'relative',
          width: '100%',
          height: '400px',
        }}
      >
        {initialized ? <CastMediaPlayer /> : null}
      </div>
    </div>
  );

  async function userLogin(accessData: AccessData) {
    await Auth.signIn(
      //@ts-expect-error todo fix type
      accessData.user,
      //@ts-expect-error todo fix type
      accessData.password
    );

    const credentials = await Auth.currentUserCredentials();
    console.log('credentials', credentials);

    AWS.config.update({
      credentials,
      region: config.config.REGION,
    });
  }
}

async function loginFromToken(accessData: AccessData) {
  console.log('loginFromToken accessData', accessData);
  const provider = `cognito-idp.${config.config.REGION}.amazonaws.com/${config.config.cognito.USER_POOL_ID}`;
  console.log(provider);

  const credentials = await Auth.federatedSignIn(
    provider,
    {
      token: accessData.token,
      identity_id: accessData.identityId,
      //@ts-expect-error fix type
      expires_at: accessData.expiration,
    },
    {
      name: accessData.identityId,
    }
  );
  console.log('auth.configure', Auth.configure());
  // console.log('Auth.currentSession()', await Auth.currentSession());
  console.log(credentials);
  console.info(AWS.config);

  AWS.config.update({
    credentials,
    region: config.config.REGION,
  });

  console.log('user', await Auth.currentAuthenticatedUser());
}

export default App;
