import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { config } from '@alpheus/ui';
import { AuthProvider } from '@alpheus/ui';
import { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';
import App from './app/app';
import { CastReceiverProvider } from '@alpheus/cast';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { HubCapsule } from '@aws-amplify/core';
import { Hub } from 'aws-amplify';

const Amplify = config.default;
config.start();
Amplify.addPluggable(new AmazonAIPredictionsProvider());
Hub.listen('pubsub', (data: HubCapsule) => {
  console.log(data);
});
const iotEndpoint = process.env.NX_IOT_ENDPOINT;
Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: config.config.REGION,
    aws_pubsub_endpoint: `wss://${iotEndpoint}/mqtt`,
  })
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <CastReceiverProvider>
      <Authenticator.Provider>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </Authenticator.Provider>
    </CastReceiverProvider>
  </StrictMode>
);
