/// <reference types="@types/chromecast-caf-sender" />
import React, {
  ReactNode,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import { createContext } from 'react';
import { CastLoader } from './CastLoader';

type CastSenderContextType = {
  initialized: boolean;
  connected: boolean;
  deviceName: string;
  sendCastMessage: (namespace: string, data: unknown) => void;
};
const CastSenderContext = createContext<CastSenderContextType>({
  initialized: false,
  connected: false,
  deviceName: '',
  sendCastMessage: (namespace: string, data: unknown) => {
    throw Error('not ready');
  },
});

interface CastSenderProviderProps extends Partial<cast.framework.CastOptions> {
  children?: ReactNode;
  autoJoinPolicy?: chrome.cast.AutoJoinPolicy;
}

export const CastSenderProvider = ({
  children,
  receiverApplicationId,
  autoJoinPolicy,
  language,
  resumeSavedSession,
}: CastSenderProviderProps) => {
  const [connected, setConnected] = useState<boolean>(false);
  const [deviceName, setDeviceName] = useState<string>('');
  const [castInitialized, setCastInititalized] = useState<boolean>(false);
  const [session, setSession] = useState<cast.framework.CastSession | null>(
    null
  );

  useEffect(() => {
    CastLoader.loadSenderSDK().then(() => {
      setCastInititalized(true);
    });
  }, []);

  const resetCast = useCallback(() => {
    setConnected(false);
    setDeviceName('');
    setSession(null);
  }, []);

  const sendCastMessage = useCallback(
    (namespace: string, data: unknown) => {
      if (session) {
        session.sendMessage(namespace, data);
      }
    },
    [session]
  );

  /* onCast Initalized */
  useEffect(() => {
    const onSessionStateChange = (
      data: cast.framework.SessionStateEventData
    ) => {
      if (
        data.sessionState ===
          window.cast.framework.SessionState.SESSION_RESUMED ||
        data.sessionState === window.cast.framework.SessionState.SESSION_STARTED
      ) {
        setConnected(true);
        setSession(data.session);
      }
      if (
        data.sessionState === window.cast.framework.SessionState.SESSION_ENDED
      ) {
        resetCast();
        setConnected(false);
      }
    };

    if (window.chrome && window.chrome.cast && window.cast) {
      const _autoJoinPolicy = autoJoinPolicy
        ? autoJoinPolicy
        : window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED;
      window.cast.framework.CastContext.getInstance().setOptions({
        receiverApplicationId,
        resumeSavedSession,
        autoJoinPolicy: _autoJoinPolicy,
        language,
      });
      // const player = new window.cast.framework.RemotePlayer();
      // setPlayer(player);
      // setPlayerController(
      //   new window.cast.framework.RemotePlayerController(player)
      // );

      window.cast.framework.CastContext.getInstance().addEventListener(
        window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
        onSessionStateChange
      );
    }
  }, [
    autoJoinPolicy,
    castInitialized,
    language,
    receiverApplicationId,
    resetCast,
    resumeSavedSession,
  ]);

  const value = useMemo(() => {
    const value = {
      connected,
      initialized: castInitialized,
      deviceName,
      // player,
      // playerController,
      sendCastMessage,
    };
    return value;
  }, [
    castInitialized,
    connected,
    deviceName,
    // player,
    // playerController
    sendCastMessage,
  ]);
  return (
    <CastSenderContext.Provider value={value}>
      {children}
    </CastSenderContext.Provider>
  );
};

export const useCast = () => {
  const context = useContext(CastSenderContext);
  return { ...context };
};
