declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'cast-media-player': any;
    }
  }
}

export const CastMediaPlayer = ({ ...props }) => {
  return <cast-media-player style={{ cursor: 'pointer' }} {...props} />;
};
