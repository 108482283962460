import * as scheduler from '@alpheus/scheduler';
import Semaphore from 'semaphore-async-await';

export const schedulelock = new Semaphore(1);

export const startSchedule = async (
  loader: scheduler.ScheduleLoader | undefined = undefined
) => {
  await schedulelock.acquire();
  try {
    await scheduler.start();
    const nJobs = scheduler.jobs().length;
    if (nJobs === 0) {
      await scheduler.load(loader);
    }
  } finally {
    await schedulelock.release();
  }
};
