export const schedule = [
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:2',
    cron: '0 6 * * 1,2,3,4,5',
    task: 'Wake up',
    person: ['Theodore', 'Arthur', 'Dad'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:3',
    cron: '10 6 * * 1,2,3,4,5',
    task: 'Theodore get washed and ready',
    person: ['Theodore'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:4',
    cron: '25 6 * * 1,2,3,4,5',
    task: 'Arthur get washed and ready',
    person: ['Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:5',
    cron: '0 7 * * 1,2,3,4,5',
    task: 'Prepare breakfast ',
    person: ['Mum', 'Nerry'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:6',
    cron: '10 7 * * 1,2,3,4,5',
    task: 'Eat Breakfast',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:7',
    cron: '25 7 * * 1,2,3,4,5',
    task: 'Tidy up table',
    person: ['Theodore'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:8',
    cron: '30 7 * * 1,2,3,4,5',
    task: 'Wash plates',
    person: ['Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:9',
    cron: '30 8 * * 1,2,3,4,5',
    task: 'Start your piano session',
    person: ['Theodore'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:10',
    cron: '0 9 * * 1,2,3,4,5',
    task: 'Start your piano session',
    person: ['Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:11',
    cron: '20 9 * * 1,2,3,4,5',
    task: 'Complete your piano session',
    person: ['Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:12',
    cron: '30 9 * * 1,2,3,4,5',
    task: 'Go put your shoes, jacket and bag on',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:13',
    cron: '40 9 * * 1,2,3,4,5',
    task: 'Leave for swim school',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:14',
    cron: '30 6 * * 0',
    task: 'Wake up and get ready: Wake up, shower, get dressed, and make yourself a cup of coffee or tea. This will give you some time to wake up and prepare for the day ahead.',
    person: ['Dad'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:15',
    cron: '0 7 * * 0',
    task: 'Wake up boys time to wake up',
    person: ['Theodore', 'Arthur', 'Dad'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:16',
    cron: '5 7 * * 0',
    task: 'Breakfast time: Prepare breakfast for your kids and encourage them to eat a healthy meal, such as oatmeal, cereal, or eggs. Try to avoid sugary cereals or other unhealthy options, as these can cause energy crashes later in the day.',
    person: ['Dad'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:17',
    cron: '30 7 * * 0',
    task: "Getting dressed: Encourage your kids to get dressed and brush their teeth, if they haven't already done so.",
    person: ['Dad'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:18',
    cron: '0 8 * * 0',
    task: 'Playtime: Allow your kids to have some free playtime or engage in an activity that they enjoy. This will give them some time to relax and have fun before the day begins.',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:19',
    cron: '0 9 * * 0',
    task: 'Getting ready to go: Help your kids gather their belongings and prepare for leaving the house. This may include packing a bag, putting on their shoes, and checking that they have everything they need.',
    person: ['Theodore', 'Arthur', 'Dad', 'Mum'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:20',
    cron: '20 9 * * 0',
    task: 'Order uber',
    person: ['Dad', 'Mum'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:21',
    cron: '30 9 * * 0',
    task: 'Leave the house for church: Leave the house on time, ensuring that you have everything you need, such as your own bag, keys, and phone. Make sure your kids are buckled up safely in their car seats and ready to go.',
    person: ['Theodore', 'Arthur', 'Dad', 'Mum'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:22',
    cron: '0 12 * * 1,2,3,4,5',
    task: 'Prepare lunch',
    person: ['Nerry'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:23',
    cron: '30 12 * * 1,2,3,4,5',
    task: 'Boys have lunch, remember to drink your water',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:24',
    cron: '33 12 * * 1,2,3,4,5',
    task: 'Arthur eat your carrots. text Daddy when you did it ',
    person: ['Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:25',
    cron: '40 12 * * 1,2,3,4,5',
    task: 'Tidy up table',
    person: ['Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:26',
    cron: '50 12 * * 1,2,3,4,5',
    task: 'Wash plates',
    person: ['Theodore'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:27',
    cron: '0 13 * * 1,2,3,4,5',
    task: 'Start your Khan Academy Math',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:28',
    cron: '20 13 * * 1,2,3,4,5',
    task: 'Complete your Khan academy Math',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:29',
    cron: '30 13 * * 1,2,3,4,5',
    task: 'Start your Typing Club typing',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:30',
    cron: '0 14 * * 1,2,3,4,5',
    task: 'Complete your typing Club typing',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:31',
    cron: '5 14 * * 1,2,3,4,5',
    task: 'Reading time',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:32',
    cron: '35 14 * * 1,2,3,4,5',
    task: 'Freetime for art and games',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:33',
    cron: '0 15 * * 1,2,3,4,5',
    task: 'Start your Duo Lingo Spanish',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:34',
    cron: '30 15 * * 1,2,3,4,5',
    task: 'Start your coding',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:35',
    cron: '50 15 * * ',
    task: 'Get ready for piano lesson',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:36',
    cron: '0 16 * * ',
    task: 'Get ready for lesson with Miss Ericka',
    person: ['Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:37',
    cron: '15 16 * * 1,2,3,4,5',
    task: 'Start getting Dinner ready',
    person: ['Nerry'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:38',
    cron: '0 16 * * 1,3,4,5',
    task: 'Free time to play',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:39',
    cron: '30 16 * * 1,2,3,4,5',
    task: 'Practice Piano',
    person: ['Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:40',
    cron: '30 16 * * 1,2,3,4,5',
    task: 'Practice Handwriting',
    person: ['Theodore'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:41',
    cron: '45 16 * * 1,2,3,4,5',
    task: 'Practice Piano',
    person: ['Theodore'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:42',
    cron: '45 16 * * 1,2,3,4,5',
    task: 'Practice Handwriting',
    person: ['Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:43',
    cron: '15 17 * * 1,2,3,4,5',
    task: 'Eat your dinner',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:44',
    cron: '30 17 * * 1,2,3,4,5',
    task: 'Got get ready for bedtime in 15 minutes',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:45',
    cron: '0 11 * * 1',
    task: 'Check the shopping list and add any additional items',
    person: ['Nerry'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:46',
    cron: '40 17 * * 1,2,3,4,5,6,0',
    task: 'Take your nightime vitamins',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:47',
    cron: '45 17 * * 1,2,3,4,5',
    task: 'Start washtime',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:48',
    cron: '0 18 * * 1,2,3,4,5',
    task: 'Relax and talk with your brother before bedtime',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:49',
    cron: '0 19 * * 1,2,3,4',
    task: 'Read your books from the library',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:50',
    cron: '30 19 * * 1,2,3,4,5',
    task: 'Go to bed',
    person: ['Theodore', 'Arthur'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:51',
    cron: '0 18 * * 5',
    task: 'Get ready for movie night!',
    person: ['Theodore', 'Arthur', ' Nerry'],
  },
  {
    external_id: '1-wBfiou9wJfBs2OXzuDv4TznHAuTnZXsz17mx3vf0OQ:52',
    cron: '0 19 * * 5',
    task: 'MOVIE NIGHT!!',
    person: ['Theodore', 'Arthur', ' Mummy', 'Daddy'],
  },
];
