export function getCallerFilePath(position = 2): string | undefined {
  if (position >= Error.stackTraceLimit) {
    throw new TypeError(
      `getCallerFile(position) requires position be less then Error.stackTraceLimit but position was: \`${position}\` and Error.stackTraceLimit was: \`${Error.stackTraceLimit}\``
    );
  }

  const oldPrepareStackTrace = Error.prepareStackTrace;
  Error.prepareStackTrace = (_, stack) => stack;
  const { stack } = new Error();
  Error.prepareStackTrace = oldPrepareStackTrace;

  if (stack !== null && typeof stack === 'object') {
    // stack[0] holds this file
    // stack[1] holds where this function was called
    // stack[2] holds the file we're interested in
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore stack[position] is not defined
    const file = stack[position];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return file ? file.getFileName() : undefined;
  }
  return undefined;
}
