import { Amplify } from 'aws-amplify';
// import '../css/styles.css';
import '../css/roboto.css';
import '@aws-amplify/ui-react/styles.css';

export const config = {
  // Backend config
  REGION: process.env.NX_REGION as string,
  cognito: {
    USER_POOL_ID: process.env.NX_USER_POOL_ID as string,
    APP_CLIENT_ID: process.env.NX_USER_POOL_WEB_CLIENT_ID as string,
    IDENTITY_POOL_ID: process.env.NX_IDENTITY_POOL_ID as string,
  },
};
export function start() {
  Amplify.configure({
    Auth: {
      // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: config.cognito.IDENTITY_POOL_ID,

      // (required)- Amazon Cognito Region
      region: config.REGION,

      // (optional) - Amazon Cognito User Pool ID
      userPoolId: config.cognito.USER_POOL_ID,

      // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,

      // (optional) - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
    },
    predictions: {
      convert: {
        translateText: {
          region: config.REGION,
          proxy: false,
          defaults: {
            sourceLanguage: 'en',
            targetLanguage: 'zh',
          },
        },
        speechGenerator: {
          region: config.REGION,
          proxy: false,
          defaults: {
            VoiceId: 'Ivy',
            LanguageCode: 'en-US',
          },
        },
        transcription: {
          region: config.REGION,
          proxy: false,
          defaults: {
            language: 'en-US',
          },
        },
      },
      identify: {
        identifyText: {
          proxy: false,
          region: config.REGION,
          defaults: {
            format: 'PLAIN',
          },
        },
        identifyEntities: {
          proxy: false,
          region: config.REGION,
          celebrityDetectionEnabled: true,
          // defaults: {
          //   collectionId: 'identifyEntities8b89c648-test',
          //   maxEntities: 50,
          // },
        },
        identifyLabels: {
          proxy: false,
          region: config.REGION,
          defaults: {
            type: 'LABELS',
          },
        },
      },
      interpret: {
        interpretText: {
          region: config.REGION,
          proxy: false,
          defaults: {
            type: 'ALL',
          },
        },
      },
    },
  });
}

export default Amplify;
