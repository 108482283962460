import { Agenda } from '@alpheus/agenda-core';
import { Hub } from 'aws-amplify';

import { schedule as customSchedule } from './data';
export type ScheduleLoader = () => Promise<
  (
    | {
        external_id: string;
        cron: string;
        task: string;
        person: string[];
      }
    | {
        id: string;
        cron: string;
        task: string;
        person: string[];
      }
  )[]
>;

const defaultSchedules: ScheduleLoader = () => {
  return Promise.resolve([
    ...customSchedule,
    ...(process.env.NX_TASK_TARGET_CONFIGURATION === 'development'
      ? [
          {
            //
            id: 'testing task',
            cron: '*/5 2-6 * * *',
            task: `testing task`,
            person: ['Dad'],
          },
        ]
      : []),
  ]);
};
// Create an instance of Agenda
const agenda = new Agenda({
  inMemory: true,
  processEvery: '20 seconds',
});

// Define the job types
agenda.define('notify', (job) => {
  const task = job.attrs.data;
  console.log(
    `Job: ${job.attrs.name} is running at ${new Date()} with ${task.task}}`
  );
  console.log(task);
  Hub.dispatch('alpheus/notify', {
    event: 'alpheus:notify',
    data: task,
  });
});

agenda.define('status', (job) => {
  const db = job.agenda.db;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-expect-error db is not defined
  const tasks = db._collection.store;
  console.log(
    `Job: ${job.attrs.name} is running at ${new Date().toISOString()}`
  );
  console.log(tasks);
});

export const load = async (
  scheduleLoader: ScheduleLoader | undefined = undefined
) => {
  await agenda.cancel({
    name: { $ne: 'fake name to force clean up of all db jobs' },
  });

  console.log(`loading schedules`);

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error db is not defined
    const tasks = agenda.db._collection.store;
    if (tasks.length > 0) {
      console.log(`task already registered`);
      return;
    }
  } catch (error) {
    console.log(`error loading task ${error}`);
  }
  const schedules = await (scheduleLoader === undefined
    ? defaultSchedules
    : scheduleLoader)();

  for (const task of schedules) {
    console.log(`scheduling ${task.cron}: ${task.task}`);
    const job = await agenda.create('notify', task);
    job.repeatEvery(task.cron, {
      timezone: 'America/New_York',
    });
    await job.save();
  }

  if (process.env.NX_TASK_TARGET_CONFIGURATION === 'development') {
    await agenda.every('*/3 * * * *', 'status', undefined, {
      timezone: 'America/New_York',
    });
  }
};

agenda.on('start', (job) => {
  console.log('Job %s starting', job.attrs.name);
});

agenda.on('error', () => {
  console.log('agenda error');
});

agenda.on('ready', () => {
  console.log('agenda ready');
});

agenda.on('complete', (job) => {
  console.log(`Job ${job.attrs.name} finished`);
});
agenda.on('success', (job) => {
  console.log(`Job ${job.attrs.name} success`);
});

agenda.on('fail', (err, job) => {
  console.log(`Job ${job.attrs.name} with error: ${err.message}`);
});

export const start = async () => {
  await agenda.stop();
  console.log(`starting scheduler ${new Date().toISOString()}`);
  await agenda.start();

  // }
};

export const jobs = () => {
  const db = agenda.db;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-expect-error db is not defined
  const jobs = db._collection.store;

  return jobs;
};
