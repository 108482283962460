// ** React Imports
import {
  createContext,
  useEffect,
  useState,
  ReactNode,
  useContext,
} from 'react';

// ** Amplify
import { useAuthenticator } from '@aws-amplify/ui-react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

// ** Types
import { useNavigate } from 'react-router-dom';
export type ErrCallbackType = (err: { [key: string]: string }) => void;

export type UserDataType = {
  role: string;
  email: string;
  fullName: string;
  username: string;
  avatar?: string | null;
};

export type AuthValuesType = {
  loading: boolean;
  logout: () => void;
  user: UserDataType | null;
  setLoading: (value: boolean) => void;
  setUser: (value: UserDataType | null) => void;
};

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  logout: () => Promise.resolve(),
};

const AuthContext = createContext(defaultProvider);

const getAttributes = async (
  user: CognitoUser
): Promise<Record<string, string>> => {
  return new Promise((resolve, reject) => {
    const attributes: Record<string, string> = {};
    user.getUserAttributes(function (err, result) {
      if (err) {
        return reject(err.message || JSON.stringify(err));
      }
      if (result) {
        for (const attr of result) {
          attributes[attr.getName()] = attr.getValue();
        }
        resolve(attributes);
      }
    });
  });
};

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user);
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading);

  // ** Hooks
  const navigate = useNavigate();
  const { authStatus, signOut } = useAuthenticator((context) => [
    context.authStatus,
  ]);

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      try {
        if (authStatus === 'configuring') {
          setLoading(true);
          return;
        }
        if (authStatus === 'authenticated') {
          const loggedInUser =
            await (Auth.currentAuthenticatedUser() as unknown as CognitoUser);

          const userAttributes = await getAttributes(loggedInUser);
          console.log(userAttributes);

          const info = await Auth.currentCredentials();
          console.log(info);

          setUser({
            role: 'admin',
            fullName: 'John Doe',
            username: loggedInUser.getUsername(),
            email: userAttributes.email,
          });
          setLoading(false);
          return;
        }
      } catch (error) {
        console.error(error);
      }
      setUser(null);
      setLoading(false);
    };

    initAuth();
  }, [authStatus]);

  const handleLogout = () => {
    signOut();
    setUser(null);
    navigate('/login');
  };

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    logout: handleLogout,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);

export { AuthContext, AuthProvider, useAuth, getAttributes };
