export const SENDER_SDK_URL =
  '//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';

export const RECEIVER_SDK_URL =
  '//www.gstatic.com/cast/sdk/libs/caf_receiver/v3/cast_receiver_framework.js';

export const RECEIVER_DEBUG_SDK_URL =
  '//www.gstatic.com/cast/sdk/libs/devtools/debug_layer/caf_receiver_logger.js';

export function loadScriptAsync(
  url: string
): Promise<HTMLScriptElement | string> {
  return new Promise((resolve, reject) => {
    let r = false;
    const t = document.getElementsByTagName('script')[0];
    const s = document.createElement('script');

    if (t.src.replace(/^https?:/g, '') === url) {
      console.log('already loading');
      resolve('');
      return;
    }

    s.type = 'text/javascript';
    s.src = url;
    s.async = true;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    s.onload = s.onreadystatechange = function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        r = true;
        resolve(this);
      }
    };
    s.onerror = s.onabort = reject;
    if (t && t.parentNode) {
      t.parentNode.insertBefore(s, t);
    }
  });
}

export class CastLoader {
  static loadSenderSDK(): Promise<void> {
    return new Promise((resolve, reject) => {
      window['__onGCastApiAvailable'] = (isAvailable: boolean) =>
        CastLoader.onGCastApiAvailable(isAvailable, resolve);

      CastLoader.loadCastSDK()
        .then(() => {
          console.warn('Cast sender lib has been loaded successfully');
        })
        .catch((e) => {
          console.warn('Cast sender lib loading failed', e);
          reject(e);
        });
    });
  }

  static async loadRecieverSDK(): Promise<void> {
    await loadScriptAsync(RECEIVER_SDK_URL);
    await loadScriptAsync(RECEIVER_DEBUG_SDK_URL);
  }
  static loadCastSDK(): Promise<string | HTMLScriptElement> {
    if (window['cast'] && window['cast']['framework']) {
      return Promise.resolve('');
    }
    return loadScriptAsync(SENDER_SDK_URL);
  }

  static onGCastApiAvailable(isAvailable: boolean, resolve: () => void): void {
    if (isAvailable) {
      resolve();
    } else {
      console.warn(`Google cast API isn't available yet`);
    }
  }
}
